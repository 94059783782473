<content-loading-animation *ngIf="loading"></content-loading-animation>
<form id="form" novalidate #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit()" *ngIf="!loading">
    <app-form-title [titles]="['AWS_MEDIACONNECT_TITLE', 'SOURCE']" [isEdit]="isEdit" [isClone]="isClone"></app-form-title>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <app-form-section-title [title]="'INFO' | translate"></app-form-section-title>
                    <!-- Name -->
                    <app-input-generic-field
                        zmid="form_name"
                        duplicateName="sourceNames"
                        [formControl]="nameControl"
                        [textFieldName]="'NAME' | translate"
                        [disabled]="isEdit || saving"
                        [isParentFormSubmitted]="form.submitted"
                        type="text"
                    ></app-input-generic-field>
                    <!-- Access Tags -->

                    <zx-access-tags-select
                        zmid="form_access-tags"
                        [formControl]="tagsControl"
                        [label]="'TAGS' | translate"
                        name="resource_tag_ids"
                        [isParentFormSubmitted]="form.submitted"
                    ></zx-access-tags-select>

                    <!-- Alerting Profile -->
                    <div class="form-group">
                        <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                            "ALERTING_PROFILE" | translate
                        }}</label>
                        <zx-alerting-profile-select
                            name="alertingProfile"
                            [(model)]="source.alertingProfile"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                        ></zx-alerting-profile-select>
                    </div>

                    <div class="form-section-title">
                        <h3 class="section-title" title="{{ 'INPUT' | translate }}">
                            {{ "INPUT" | translate }}
                        </h3>
                    </div>

                    <!-- Protocol -->
                    <fieldset class="form-group bordered">
                        <legend>
                            {{ "PROTOCOL" | translate
                            }}<fa-icon *ngIf="!isEdit" icon="lock" size="sm" [ngbTooltip]="ProtocolContent" triggers="hover click" [closeDelay]="500"></fa-icon
                            ><ng-template #ProtocolContent
                                ><p class="mb-0">
                                    {{ "TOOLTIP.SOURCE_PROTOCOL" | translate }}
                                </p></ng-template
                            >
                        </legend>
                        <div class="form-check form-check-inline">
                            <input
                                type="radio"
                                id="protocol_zixi"
                                name="protocol_zixi"
                                class="form-check-input"
                                value="zixi-push"
                                [(ngModel)]="protocol"
                                (change)="resetForm()"
                                [disabled]="isEdit"
                            />
                            <label class="form-check-label" for="protocol_zixi">{{ "ZIXI" | translate }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                type="radio"
                                id="protocol_rtp_fec"
                                name="protocol_rtp_fec"
                                class="form-check-input"
                                value="rtp-fec"
                                [(ngModel)]="protocol"
                                (change)="resetForm()"
                                [disabled]="isEdit"
                            />
                            <label class="form-check-label" for="protocol_rtp_fec">{{ "MC_RTP_FEC" | translate }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                type="radio"
                                id="protocol_rtp"
                                name="protocol_rtp"
                                class="form-check-input"
                                value="rtp"
                                [(ngModel)]="protocol"
                                (change)="resetForm()"
                                [disabled]="isEdit"
                            />
                            <label class="form-check-label" for="protocol_rtp">{{ "MC_RTP" | translate }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                type="radio"
                                id="protocol_rist"
                                name="protocol_rist"
                                class="form-check-input"
                                value="rist"
                                [(ngModel)]="protocol"
                                (change)="resetForm()"
                                [disabled]="isEdit"
                            />
                            <label class="form-check-label" for="protocol_rist">{{ "RIST" | translate }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                type="radio"
                                id="protocol_srt"
                                name="protocol_srt"
                                class="form-check-input"
                                value="srt-listener"
                                [(ngModel)]="protocol"
                                (change)="resetForm()"
                                [disabled]="isEdit"
                            />
                            <label class="form-check-label" for="protocol_srt">{{ "SRT" | translate }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                type="radio"
                                id="protocol_cdi"
                                name="protocol_cdi"
                                class="form-check-input"
                                value="cdi"
                                [(ngModel)]="protocol"
                                (change)="resetForm()"
                                [disabled]="isEdit"
                            />
                            <label class="form-check-label" for="protocol_cdi">{{ "CDI" | translate }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                type="radio"
                                id="protocol_jpegxs"
                                name="protocol_jpegxs"
                                class="form-check-input"
                                value="st2110-jpegxs"
                                [(ngModel)]="protocol"
                                (change)="resetForm()"
                                [disabled]="isEdit"
                            />
                            <label class="form-check-label" for="protocol_jpegxs">{{ "JPEGXS" | translate }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                type="radio"
                                id="protocol_elemental_link"
                                name="protocol_elemental_link"
                                class="form-check-input"
                                value="elemental_link"
                                [(ngModel)]="protocol"
                                (change)="resetForm()"
                                [disabled]="isEdit"
                            />
                            <label class="form-check-label" for="protocol_elemental_link">{{ "ELEMENTAL_LINK" | translate }}</label>
                        </div>
                    </fieldset>
                    <!-- Type -->
                    <div *ngIf="protocol === 'zixi-push'">
                        <!-- Feeder / Broadcaste / Other -->
                        <fieldset class="form-group bordered">
                            <legend>
                                {{ "APPLICATION" | translate
                                }}<fa-icon *ngIf="!isEdit" icon="lock" size="sm" [ngbTooltip]="AppContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #AppContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.SOURCE_APPLICATION" | translate }}
                                    </p></ng-template
                                >
                            </legend>
                            <div class="form-check form-check-inline">
                                <input
                                    type="radio"
                                    id="type_feeder"
                                    name="type_feeder"
                                    class="form-check-input"
                                    value="feeder"
                                    [(ngModel)]="sourceType"
                                    (change)="resetForm()"
                                    [disabled]="isEdit"
                                />
                                <label class="form-check-label" for="type_feeder"
                                    >{{ "FEEDER" | translate
                                    }}<fa-icon icon="info-circle" [ngbTooltip]="FeederContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                    ><ng-template #FeederContent
                                        ><p class="mb-0">
                                            {{ "TOOLTIP.SOURCE_FEEDER" | translate }}
                                        </p></ng-template
                                    ></label
                                >
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    type="radio"
                                    id="type_broadcaster"
                                    name="type_broadcaster"
                                    class="form-check-input"
                                    value="broadcaster"
                                    [(ngModel)]="sourceType"
                                    (change)="resetForm()"
                                    [disabled]="isEdit"
                                />
                                <label class="form-check-label" for="type_broadcaster"
                                    >{{ "BROADCASTER" | translate
                                    }}<fa-icon icon="info-circle" [ngbTooltip]="BroadcasterContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                    ><ng-template #BroadcasterContent
                                        ><p class="mb-0">
                                            {{ "TOOLTIP.SOURCE_BROADCASTER" | translate }}
                                        </p></ng-template
                                    ></label
                                >
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    type="radio"
                                    id="type_zec"
                                    name="type_zec"
                                    class="form-check-input"
                                    value="zec"
                                    [(ngModel)]="sourceType"
                                    (change)="resetForm()"
                                    [disabled]="isEdit"
                                />
                                <label class="form-check-label" for="type_zec"
                                    >{{ "ZEC" | translate
                                    }}<fa-icon icon="info-circle" [ngbTooltip]="ZecContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                    ><ng-template #ZecContent
                                        ><p class="mb-0">
                                            {{ "TOOLTIP.SOURCE_ZEC" | translate }}
                                        </p></ng-template
                                    ></label
                                >
                            </div>

                            <div class="form-check form-check-inline">
                                <input
                                    type="radio"
                                    id="type_other"
                                    name="type_other"
                                    class="form-check-input"
                                    value="other"
                                    [(ngModel)]="sourceType"
                                    (change)="resetForm()"
                                    [disabled]="isEdit"
                                />
                                <label class="form-check-label" for="type_other"
                                    >{{ "OTHER" | translate
                                    }}<fa-icon icon="info-circle" [ngbTooltip]="OtherContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                    ><ng-template #OtherContent
                                        ><p class="mb-0">
                                            {{ "TOOLTIP.SOURCE_OTHER" | translate }}
                                        </p></ng-template
                                    ></label
                                >
                            </div>
                        </fieldset>

                        <!-- Feeder Select -->
                        <div class="form-group" *ngIf="sourceType === 'feeder'">
                            <label for="feeder_id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.feeder_id?.errors }"
                                >{{ "FEEDER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <zx-zec-select
                                name="feeder_id"
                                [(model)]="source.feeder_id"
                                required="true"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.feeder_id?.errors }"
                                (modelChange)="getFeederDetails(source.feeder_id, true)"
                                [zecType]="'FEEDER'"
                            ></zx-zec-select>
                            <div *ngIf="form.controls.feeder_id?.invalid" class="invalid-feedback">
                                <div *ngIf="form.controls.feeder_id?.errors.required">{{ "FEEDER" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Feeder Input Select -->
                        <div class="form-group" *ngIf="sourceType === 'feeder'">
                            <label for="feeder_input" [ngClass]="{ 'is-invalid': form.submitted && feederInput.errors }">
                                {{ "FEEDER_INPUT" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                            <ng-select
                                id="feeder_input"
                                name="feeder_input"
                                [items]="feederInputs"
                                [clearable]="false"
                                bindValue="name"
                                placeholder="{{ 'SELECT_FEEDER_INPUT' | translate }}"
                                [(ngModel)]="source.input_id"
                                #feederInput="ngModel"
                                [ngClass]="{ 'form-control is-invalid': form.submitted && feederInput.errors }"
                                required
                                [loading]="feederDetailsLoading"
                                [searchFn]="feederInputSearch"
                            >
                                <ng-template ng-label-tmp let-item="item" *ngIf="feederDetailsLoading"> {{ "LOADING" | translate }}... </ng-template>
                                <ng-template
                                    ng-label-tmp
                                    let-item="item"
                                    let-index="index"
                                    let-search="searchTerm"
                                    *ngIf="!feederDetailsLoading && !feederInputs.length"
                                >
                                    {{ "SELECT_FEEDER_INPUT" | translate }}
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item" *ngIf="!feederDetailsLoading">
                                    <zx-status-icon class="me-1" [model]="item | feederInputStatus" [status]="item | feederInputStatus"></zx-status-icon>
                                    <span>{{ item | feederInput }}</span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm" *ngIf="!feederDetailsLoading">
                                    <zx-status-icon class="me-1" [model]="item | feederInputStatus" [status]="item | feederInputStatus"></zx-status-icon>
                                    <span>{{ item | feederInput }}</span>
                                </ng-template>
                            </ng-select>
                            <small *ngIf="!feederInputs || !feederInputs.length">{{ "NO_INPUTS" | translate }}</small>
                            <div *ngIf="feederInput.invalid" class="invalid-feedback">
                                <div *ngIf="feederInput.errors.required">{{ "FEEDER_INPUT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Broadcaster Select -->
                        <div class="form-group" *ngIf="sourceType === 'broadcaster'">
                            <label for="broadcaster_id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_id?.errors }"
                                >{{ "BROADCASTER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <zx-broadcaster-select
                                name="broadcaster_id"
                                [(model)]="source.broadcaster_id"
                                required="true"
                                (modelChange)="getBroadcasterDetails(source.broadcaster_id, true)"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_id?.errors }"
                            ></zx-broadcaster-select>
                            <div *ngIf="form.controls.broadcaster_id?.invalid" class="invalid-feedback">
                                <div *ngIf="form.controls.broadcaster_id?.errors.required">
                                    {{ "BROADCASTER" | translate }} {{ "IS_REQUIRED" | translate }}.
                                </div>
                            </div>
                        </div>
                        <!-- Broadcaster Input Select -->
                        <div class="form-group" *ngIf="sourceType === 'broadcaster'">
                            <label for="broadcaster_input" [ngClass]="{ 'is-invalid': form.submitted && broadcasterInput.errors }">
                                {{ "BROADCASTER_INPUT" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                            <ng-select
                                id="broadcaster_input"
                                name="broadcaster_input"
                                [items]="broadcasterInputs"
                                [clearable]="false"
                                bindValue="id"
                                placeholder="{{ 'SELECT_BROADCASTER_INPUT' | translate }}"
                                [(ngModel)]="source.input_id"
                                #broadcasterInput="ngModel"
                                [ngClass]="{ 'form-control is-invalid': form.submitted && broadcasterInput.errors }"
                                required
                                [loading]="broadcasterDetailsLoading"
                                [searchFn]="broadcasterInputSearch"
                            >
                                <ng-template
                                    ng-label-tmp
                                    let-item="item"
                                    let-index="index"
                                    let-search="searchTerm"
                                    *ngIf="!broadcasterDetailsLoading && !broadcasterInputs.length"
                                >
                                    {{ "SELECT_BROADCASTER_INPUT" | translate }}
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item" *ngIf="!broadcasterDetailsLoading">
                                    <zx-status-icon
                                        class="me-1"
                                        [model]="item | broadcasterInputStatus"
                                        [status]="item | broadcasterInputStatus"
                                    ></zx-status-icon>
                                    <span>{{ item | broadcasterInput }}</span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm" *ngIf="!broadcasterDetailsLoading">
                                    <zx-status-icon
                                        class="me-1"
                                        [model]="item | broadcasterInputStatus"
                                        [status]="item | broadcasterInputStatus"
                                    ></zx-status-icon>
                                    <span>{{ item | broadcasterInput }}</span>
                                </ng-template>
                            </ng-select>
                            <small *ngIf="!broadcasterInputs || !broadcasterInputs.length">{{ "NO_INPUTS" | translate }}</small>
                            <div *ngIf="broadcasterInput.invalid" class="invalid-feedback">
                                <div *ngIf="broadcasterInput.errors.required">{{ "BROADCASTER_INPUT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                        <!-- ZEC Select -->
                        <div class="form-group" *ngIf="sourceType === 'zec'">
                            <label for="zec_id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.zec_id?.errors }"
                                >{{ "ZEC" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <zx-zec-select
                                name="zec_id"
                                [(model)]="source.zec_id"
                                required="true"
                                [filterRole]="'Feeder'"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.zec_id?.errors }"
                                (modelChange)="getZecDetails(source.zec_id, true)"
                            ></zx-zec-select>
                            <div *ngIf="form.controls.zec_id?.invalid" class="invalid-feedback">
                                <div *ngIf="form.controls.zec_id?.errors.required">{{ "ZEC" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Zec Input Select -->
                        <div class="form-group" *ngIf="sourceType === 'zec'">
                            <label for="zec_input" [ngClass]="{ 'is-invalid': form.submitted && zec_input.errors }">
                                {{ "ZEC_INPUT" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                            <ng-select
                                id="zec_input"
                                name="zec_input"
                                [items]="zecInputs"
                                [clearable]="false"
                                bindValue="id"
                                placeholder="{{ 'SELECT_ZEC_INPUT' | translate }}"
                                [(ngModel)]="source.input_id"
                                #zec_input="ngModel"
                                [ngClass]="{ 'form-control is-invalid': form.submitted && zec_input.errors }"
                                required
                                [loading]="zecDetailsLoading"
                                [searchFn]="zecInputSearch"
                            >
                                <ng-template ng-label-tmp let-item="item" *ngIf="zecDetailsLoading"> {{ "LOADING" | translate }}... </ng-template>
                                <ng-template
                                    ng-label-tmp
                                    let-item="item"
                                    let-index="index"
                                    let-search="searchTerm"
                                    *ngIf="!zecDetailsLoading && !zecInputs.length"
                                >
                                    {{ "SELECT_ZEC_INPUT" | translate }}
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item" *ngIf="!zecDetailsLoading">
                                    <zx-status-icon class="me-1" [model]="item | zecInputStatus" [status]="item | zecInputStatus"></zx-status-icon>
                                    <span>{{ item | zecInput }}</span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm" *ngIf="!zecDetailsLoading">
                                    <zx-status-icon class="me-1" [model]="item | zecInputStatus" [status]="item | zecInputStatus"></zx-status-icon>
                                    <span>{{ item | zecInput }}</span>
                                </ng-template>
                            </ng-select>
                            <small *ngIf="!zecInputs || !zecInputs.length">{{ "NO_INPUTS" | translate }}</small>
                            <div *ngIf="zec_input.invalid" class="invalid-feedback">
                                <div *ngIf="zec_input.errors.required">{{ "ZEC_INPUT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>

                        <!-- Broadcaster Bonding -->
                        <div class="form-group" *ngIf="sourceType === 'broadcaster'">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="bonding" name="bonding" [(ngModel)]="bondingOption" />
                                <label class="form-check-label" for="bonding"
                                    >{{ "BONDING" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                            </div>
                        </div>
                        <!-- Feeder Bonding -->
                        <fieldset class="form-group" *ngIf="sourceType === 'feeder'">
                            <legend for="bonding">{{ "BONDING" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon></legend>
                            <mat-button-toggle-group name="bondingOption" aria-label="bondingOption" [(ngModel)]="bondingOption">
                                <mat-button-toggle [value]="0">{{ "NONE" | translate }}</mat-button-toggle>
                                <mat-button-toggle [value]="1">{{ "MANUAL" | translate }}</mat-button-toggle>
                                <mat-button-toggle [value]="2">{{ "AUTO" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                        <!-- ZEC Bonding -->
                        <div class="form-group" *ngIf="sourceType === 'zec'">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="bonding" name="bonding" [(ngModel)]="bondingOption" />
                                <label class="form-check-label" for="bonding"
                                    >{{ "BONDING" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                            </div>
                        </div>

                        <!-- Feeder Output NIC Select -->
                        <div class="form-group" *ngIf="sourceType === 'feeder' && bondingOption === 0">
                            <label for="feeder_output_nic">
                                {{ "FEEDER_OUTPUT_NIC" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <ng-select
                                id="feeder_output_nic"
                                name="feeder_output_nic"
                                [items]="feederOutputsAll"
                                [clearable]="false"
                                bindValue="value"
                                bindLabel="name"
                                placeholder="{{ 'SELECT_FEEDER_OUTPUT_NIC' | translate }}"
                                [(ngModel)]="source.output_nic"
                                [disabled]="!source.feeder_id"
                            >
                            </ng-select>
                        </div>
                        <!-- Broadcaster Output NIC Select -->
                        <div class="form-group" *ngIf="sourceType === 'broadcaster' && bondingOption !== 1 && bondingOption !== true">
                            <label for="broadcaster_output_nic">
                                {{ "BROADCASTER_OUTPUT_NIC" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                            <ng-select
                                id="broadcaster_output_nic"
                                name="broadcaster_output_nic"
                                [items]="broadcasterOutputsAll"
                                [loading]="broadcasterDetailsLoading"
                                [clearable]="false"
                                bindValue="value"
                                bindLabel="name"
                                placeholder="{{ 'SELECT_BROADCASTER_OUTPUT_NIC' | translate }}"
                                [(ngModel)]="source.output_nic"
                                [disabled]="!source.broadcaster_id"
                            >
                            </ng-select>
                        </div>

                        <!-- ZEC Output NIC Select -->
                        <div class="form-group" *ngIf="sourceType === 'zec' && bondingOption !== 1 && bondingOption !== true">
                            <label for="zec_output_nic">
                                {{ "ZEC_OUTPUT_NIC" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                            <ng-select
                                id="zec_output_nic"
                                name="zec_output_nic"
                                [items]="zecOutputsAll"
                                [loading]="zecDetailsLoading"
                                [clearable]="false"
                                bindValue="value"
                                bindLabel="name"
                                placeholder="{{ 'SELECT_ZEC_OUTPUT_NIC' | translate }}"
                                [(ngModel)]="source.output_nic"
                                [disabled]="!source.zec_id"
                            >
                            </ng-select>
                        </div>

                        <!-- Feeder Output NIC Table -->
                        <div class="form-group" *ngIf="sourceType === 'feeder' && bondingOption === 1">
                            <label for="feeder_output_nics" [ngClass]="{ 'is-invalid': form.submitted && selectedFeederOutputNICs.length === 0 }"
                                >{{ "FEEDER_OUTPUT_NICS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <div class="table-responsive">
                                <table
                                    class="table table-sm bordered m-0"
                                    [ngClass]="{ 'is-invalid': form.submitted && selectedFeederOutputNICs.length === 0 }"
                                >
                                    <thead>
                                        <tr>
                                            <th scope="col">{{ "OUTPUT_NIC" | translate }}</th>
                                            <th scope="col" class="w-100px">{{ "MAX_BITRATE" | translate }} [kbps]</th>
                                            <th scope="col">{{ "BACKUP" | translate }}</th>
                                            <th scope="col" class="w-50px text-end">
                                                <fa-icon
                                                    class=""
                                                    icon="info-circle"
                                                    [ngbTooltip]="FOIContent"
                                                    triggers="hover click"
                                                    [closeDelay]="500"
                                                ></fa-icon>
                                                <ng-template #FOIContent>
                                                    <p class="mb-0">{{ "TOOLTIP.SOURCE_FEEDER_OUTPUT_INSTRUCTIONS" | translate }}</p>
                                                </ng-template>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="source.feeder_id && feederOutputNICs.length > 0">
                                        <tr *ngFor="let output of selectedFeederOutputNICs; index as index">
                                            <td>
                                                {{ output.name }}
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    class="form-control form-control-sm form-control-xs"
                                                    id="output_bitrate_{{ index }}"
                                                    name="output_bitrate_{{ index }}"
                                                    [(ngModel)]="output.max_bitrate"
                                                    pattern="^\d*[1-9]\d*$"
                                                    placeholder="{{ 'MAX_BITRATE' | translate }}"
                                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls['output_bitrate_' + index]?.errors }"
                                                    required
                                                />
                                                <div *ngIf="form.controls['output_bitrate_' + index]?.invalid" class="invalid-feedback">
                                                    <div *ngIf="form.controls['output_bitrate_' + index]?.errors.required">
                                                        {{ "MAX_BITRATE" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                    </div>
                                                    <div *ngIf="form.controls['output_bitrate_' + index]?.errors.pattern">
                                                        {{ "INVALID_NUMBER" | translate }}.
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        id="backup_{{ index }}"
                                                        name="backup_{{ index }}"
                                                        [(ngModel)]="output.backup"
                                                    />
                                                    <label class="form-check-label" for="backup_{{ index }}"></label>
                                                </div>
                                            </td>
                                            <td class="text-end">
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary"
                                                    (click)="deselectFeederOutputNIC(output)"
                                                >
                                                    <fa-icon icon="minus" size="sm"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <div class="form-inline">
                                                    <div class="form-group stay-inline">
                                                        <label for="feederOutputsFilter">{{ "FILTER" | translate }}:</label>
                                                        <input
                                                            type="text"
                                                            name="feederOutputsFilter"
                                                            class="ms-1 form-control form-control-sm form-control-xs maxw-140px"
                                                            [(ngModel)]="feederOutputsFilter"
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr
                                            *ngFor="
                                                let output of feederOutputNICs
                                                    | filter : 'name' : feederOutputsFilter
                                                    | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize
                                            "
                                            (click)="selectFeederOutputNIC(output)"
                                            class="cursor-pointer row-hover"
                                        >
                                            <td class="maxw-120px ellipsis" *ngIf="output.name !== 'Any'">
                                                <ngb-highlight title="{{ output.name }}" [result]="output.name" [term]="feederOutputsFilter"></ngb-highlight>
                                            </td>
                                            <td *ngIf="output.name !== 'Any'"></td>
                                            <td *ngIf="output.name !== 'Any'"></td>
                                            <td class="text-end" *ngIf="output.name !== 'Any'">
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-xs btn-round-xs btn-outline-primary"
                                                    (click)="selectFeederOutputNIC(output)"
                                                >
                                                    <fa-icon icon="plus" size="sm"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="source.feeder_id && (feederOutputNICs | filter : 'name' : feederOutputsFilter).length === 0">
                                        <tr>
                                            <td colspan="4" class="text-center">{{ "NO_OUTPUTS" | translate }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!source.feeder_id">
                                        <tr>
                                            <td colspan="4" class="text-center">{{ "SELECT_FEEDER" | translate }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="form.submitted && selectedFeederOutputNICs.length === 0" class="invalid-feedback">
                                {{ "AT_LEAST_1_FEEDER_OUTPUT_IS_REQUIRED" | translate }}.
                            </div>
                            <div class="mt-2" *ngIf="feederOutputNICs && (feederOutputNICs | filter : 'name' : feederOutputsFilter).length > pageSize">
                                <ngb-pagination
                                    class="mb-0"
                                    [collectionSize]="(feederOutputNICs | filter : 'name' : feederOutputsFilter).length"
                                    [(page)]="page"
                                    [pageSize]="pageSize"
                                    [maxSize]="2"
                                ></ngb-pagination>
                            </div>
                        </div>
                        <!-- Broadcaster Output NIC Table -->
                        <div class="form-group" *ngIf="sourceType === 'broadcaster' && bondingOption">
                            <label for="broadcaster_output_nics" [ngClass]="{ 'is-invalid': form.submitted && selectedBroadcasterOutputNICs.length === 0 }"
                                >{{ "BROADCASTER_OUTPUT_NICS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <div class="table-responsive">
                                <table
                                    class="table table-sm bordered m-0"
                                    [ngClass]="{ 'is-invalid': form.submitted && selectedBroadcasterOutputNICs.length === 0 }"
                                >
                                    <thead>
                                        <tr>
                                            <th scope="col">{{ "OUTPUT_NIC" | translate }}</th>
                                            <th scope="col" class="w-100px">{{ "MAX_BITRATE" | translate }} [kbps]</th>
                                            <th scope="col">{{ "BACKUP" | translate }}</th>
                                            <th scope="col" class="w-50px text-end">
                                                <fa-icon
                                                    class=""
                                                    icon="info-circle"
                                                    [ngbTooltip]="BOIContent"
                                                    triggers="hover click"
                                                    [closeDelay]="500"
                                                ></fa-icon>
                                                <ng-template #BOIContent>
                                                    <p class="mb-0">{{ "TOOLTIP.SOURCE_BROADCASTER_OUTPUT_INSTRUCTIONS" | translate }}</p>
                                                </ng-template>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="source.broadcaster_id && broadcasterOutputNICs.length > 0">
                                        <tr *ngFor="let output of selectedBroadcasterOutputNICs; index as index">
                                            <td>
                                                {{ output.name }}
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    class="form-control form-control-sm form-control-xs"
                                                    id="output_bitrate_{{ index }}"
                                                    name="output_bitrate_{{ index }}"
                                                    [(ngModel)]="output.max_bitrate"
                                                    pattern="^\d*[1-9]\d*$"
                                                    placeholder="{{ 'MAX_BITRATE' | translate }}"
                                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls['output_bitrate_' + index]?.errors }"
                                                    required
                                                />
                                                <div *ngIf="form.controls['output_bitrate_' + index]?.invalid" class="invalid-feedback">
                                                    <div *ngIf="form.controls['output_bitrate_' + index]?.errors.required">
                                                        {{ "MAX_BITRATE" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                    </div>
                                                    <div *ngIf="form.controls['output_bitrate_' + index]?.errors.pattern">
                                                        {{ "INVALID_NUMBER" | translate }}.
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        id="backup_{{ index }}"
                                                        name="backup_{{ index }}"
                                                        [(ngModel)]="output.backup"
                                                    />
                                                    <label class="form-check-label" for="backup_{{ index }}"></label>
                                                </div>
                                            </td>
                                            <td class="text-end">
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary"
                                                    (click)="deselectBroadcasterOutputNIC(output)"
                                                >
                                                    <fa-icon icon="minus" size="sm"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <div class="form-inline">
                                                    <div class="form-group stay-inline">
                                                        <label for="broadcasterOutputsFilter">{{ "FILTER" | translate }}:</label>
                                                        <input
                                                            type="text"
                                                            name="broadcasterOutputsFilter"
                                                            class="ms-1 form-control form-control-sm form-control-xs maxw-140px"
                                                            [(ngModel)]="broadcasterOutputsFilter"
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr
                                            *ngFor="
                                                let output of broadcasterOutputNICs
                                                    | filter : 'name' : broadcasterOutputsFilter
                                                    | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize
                                            "
                                            class="cursor-pointer row-hover"
                                            (click)="selectBroadcasterOutputNIC(output)"
                                        >
                                            <td class="maxw-120px ellipsis" *ngIf="output.name !== 'Any'">
                                                <ngb-highlight
                                                    title="{{ output.name }}"
                                                    [result]="output.name"
                                                    [term]="broadcasterOutputsFilter"
                                                ></ngb-highlight>
                                            </td>
                                            <td *ngIf="output.name !== 'Any'"></td>
                                            <td *ngIf="output.name !== 'Any'"></td>
                                            <td class="text-end" *ngIf="output.name !== 'Any'">
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-xs btn-round-xs btn-outline-primary"
                                                    (click)="selectBroadcasterOutputNIC(output)"
                                                >
                                                    <fa-icon icon="plus" size="sm"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="source.broadcaster_id && (broadcasterOutputNICs | filter : 'name' : broadcasterOutputsFilter).length === 0">
                                        <tr>
                                            <td colspan="4" class="text-center">{{ "NO_OUTPUTS" | translate }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!source.broadcaster_id">
                                        <tr>
                                            <td colspan="4" class="text-center">{{ "SELECT_BROADCASTER" | translate }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="form.submitted && selectedBroadcasterOutputNICs.length === 0" class="invalid-feedback">
                                {{ "AT_LEAST_1_FEEDER_OUTPUT_IS_REQUIRED" | translate }}.
                            </div>
                            <div
                                class="mt-2"
                                *ngIf="broadcasterOutputNICs && (broadcasterOutputNICs | filter : 'name' : broadcasterOutputsFilter).length > pageSize"
                            >
                                <ngb-pagination
                                    class="mb-0"
                                    [collectionSize]="(broadcasterOutputNICs | filter : 'name' : broadcasterOutputsFilter).length"
                                    [(page)]="page"
                                    [pageSize]="pageSize"
                                    [maxSize]="2"
                                ></ngb-pagination>
                            </div>
                        </div>
                        <!-- Zec Output NIC Table -->
                        <div class="form-group" *ngIf="sourceType === 'zec' && bondingOption">
                            <label for="zec_output_nics" [ngClass]="{ 'is-invalid': form.submitted && selectedZecOutputNICs.length === 0 }"
                                >{{ "ZEC_OUTPUT_NICS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <div class="table-responsive">
                                <table class="table table-sm bordered m-0" [ngClass]="{ 'is-invalid': form.submitted && selectedZecOutputNICs.length === 0 }">
                                    <thead>
                                        <tr>
                                            <th scope="col">{{ "OUTPUT_NIC" | translate }}</th>
                                            <th scope="col" class="w-100px">{{ "MAX_BITRATE" | translate }} [kbps]</th>
                                            <th scope="col">{{ "BACKUP" | translate }}</th>
                                            <th scope="col" class="w-50px text-end">
                                                <fa-icon
                                                    class=""
                                                    icon="info-circle"
                                                    [ngbTooltip]="BOIContent"
                                                    triggers="hover click"
                                                    [closeDelay]="500"
                                                ></fa-icon>
                                                <ng-template #BOIContent>
                                                    <p class="mb-0">{{ "TOOLTIP.SOURCE_ZEC_OUTPUT_INSTRUCTIONS" | translate }}</p>
                                                </ng-template>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="source.zec_id && zecOutputNICs.length > 0">
                                        <tr *ngFor="let output of selectedZecOutputNICs; index as index">
                                            <td>
                                                {{ output.name }}
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    class="form-control form-control-sm form-control-xs"
                                                    id="output_bitrate_{{ index }}"
                                                    name="output_bitrate_{{ index }}"
                                                    [(ngModel)]="output.max_bitrate"
                                                    pattern="^\d*[1-9]\d*$"
                                                    placeholder="{{ 'MAX_BITRATE' | translate }}"
                                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls['output_bitrate_' + index]?.errors }"
                                                    required
                                                />
                                                <div *ngIf="form.controls['output_bitrate_' + index]?.invalid" class="invalid-feedback">
                                                    <div *ngIf="form.controls['output_bitrate_' + index]?.errors.required">
                                                        {{ "MAX_BITRATE" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                    </div>
                                                    <div *ngIf="form.controls['output_bitrate_' + index]?.errors.pattern">
                                                        {{ "INVALID_NUMBER" | translate }}.
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        id="backup_{{ index }}"
                                                        name="backup_{{ index }}"
                                                        [(ngModel)]="output.backup"
                                                    />
                                                    <label class="form-check-label" for="backup_{{ index }}"></label>
                                                </div>
                                            </td>
                                            <td class="text-end">
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary"
                                                    (click)="deselectZecOutputNIC(output)"
                                                >
                                                    <fa-icon icon="minus" size="sm"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <div class="form-inline">
                                                    <div class="form-group stay-inline">
                                                        <label for="zecOutputsFilter">{{ "FILTER" | translate }}:</label>
                                                        <input
                                                            type="text"
                                                            name="zecOutputsFilter"
                                                            class="ms-1 form-control form-control-sm form-control-xs maxw-140px"
                                                            [(ngModel)]="zecOutputsFilter"
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr
                                            *ngFor="
                                                let output of zecOutputNICs
                                                    | filter : 'name' : zecOutputsFilter
                                                    | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize
                                            "
                                            class="cursor-pointer row-hover"
                                            (click)="selectZecOutputNIC(output)"
                                        >
                                            <td class="maxw-120px ellipsis" *ngIf="output.name !== 'Any'">
                                                <ngb-highlight title="{{ output.name }}" [result]="output.name" [term]="zecOutputsFilter"></ngb-highlight>
                                            </td>
                                            <td *ngIf="output.name !== 'Any'"></td>
                                            <td *ngIf="output.name !== 'Any'"></td>
                                            <td class="text-end" *ngIf="output.name !== 'Any'">
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-xs btn-round-xs btn-outline-primary"
                                                    (click)="selectZecOutputNIC(output)"
                                                >
                                                    <fa-icon icon="plus" size="sm"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="source.zec_id && (zecOutputNICs | filter : 'name' : zecOutputsFilter).length === 0">
                                        <tr>
                                            <td colspan="4" class="text-center">{{ "NO_OUTPUTS" | translate }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!source.zec_id">
                                        <tr>
                                            <td colspan="4" class="text-center">{{ "SELECT_ZEC" | translate }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="form.submitted && selectedZecOutputNICs.length === 0" class="invalid-feedback">
                                {{ "AT_LEAST_1_ZEC_OUTPUT_IS_REQUIRED" | translate }}.
                            </div>
                            <div class="mt-2" *ngIf="zecOutputNICs && (zecOutputNICs | filter : 'name' : zecOutputsFilter).length > pageSize">
                                <ngb-pagination
                                    class="mb-0"
                                    [collectionSize]="(zecOutputNICs | filter : 'name' : zecOutputsFilter).length"
                                    [(page)]="page"
                                    [pageSize]="pageSize"
                                    [maxSize]="2"
                                ></ngb-pagination>
                            </div>
                        </div>
                    </div>
                    <!-- Ingest Port -->
                    <div class="form-group" *ngIf="!['zixi-push', 'st2110-jpegxs', 'elemental_link'].includes(protocol)">
                        <label for="ingest_port" [ngClass]="{ 'is-invalid': form.submitted && ingest_port.errors }"
                            >{{ "INGEST_PORT" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <input
                            type="number"
                            id="ingest_port"
                            name="ingest_port"
                            placeholder="{{ 'INGEST_PORT' | translate }} 1024 to 65536"
                            class="form-control"
                            min="1024"
                            max="65536"
                            pattern="^\d+$"
                            [(ngModel)]="source.ingest_port"
                            [ngClass]="{ 'is-invalid': form.submitted && ingest_port.errors }"
                            required
                            #ingest_port="ngModel"
                        />
                        <div *ngIf="ingest_port.invalid" class="invalid-feedback">
                            <div *ngIf="ingest_port.errors.required">{{ "INGEST_PORT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="ingest_port.errors.pattern">
                                {{ "INGEST_PORT" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                            </div>
                        </div>
                    </div>
                    <!-- Max Bitrate -->
                    <div
                        class="form-group"
                        *ngIf="
                            !['cdi', 'st2110-jpegxs', 'elemental_link'].includes(protocol) &&
                            ((sourceType !== 'broadcaster' && sourceType !== 'zec') || protocol !== 'zixi-push')
                        "
                    >
                        <label for="max_bitrate" [ngClass]="{ 'is-invalid': form.submitted && maxBitrate.errors }">
                            {{ "MAX_BITRATE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                        </label>
                        <ng-select
                            id="max_bitrate"
                            name="max_bitrate"
                            required
                            [items]="maxBitrates"
                            [clearable]="false"
                            placeholder="{{ 'SELECT' | translate }} {{ 'MAX_BITRATE' | translate }}"
                            [(ngModel)]="source.max_bitrate"
                            bindLabel="name"
                            bindValue="value"
                            #maxBitrate="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && maxBitrate.errors }"
                        >
                        </ng-select>
                        <div *ngIf="maxBitrate.invalid" class="invalid-feedback">
                            <div *ngIf="maxBitrate.errors.required">{{ "MAX_BITRATE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Latency -->
                    <div class="form-group" *ngIf="!['cdi', 'st2110-jpegxs', 'rtp', 'rtp-fec', 'elemental_link'].includes(protocol)">
                        <label for="latency" [ngClass]="{ 'is-invalid': form.submitted && latency.errors }"
                            >{{ "LATENCY" | translate }} [ms]<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <input
                            type="number"
                            id="latency"
                            name="latency"
                            placeholder="{{ 'LATENCY' | translate }}"
                            class="form-control"
                            min="0"
                            pattern="^\d+$"
                            [(ngModel)]="source.latency"
                            [ngClass]="{ 'is-invalid': form.submitted && latency.errors }"
                            required
                            #latency="ngModel"
                        />
                        <div *ngIf="latency.invalid" class="invalid-feedback">
                            <div *ngIf="latency.errors.required">{{ "LATENCY" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="latency.errors.pattern">{{ "LATENCY" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.</div>
                        </div>
                    </div>
                    <!-- Max Sync buffer -->
                    <div class="form-group" *ngIf="['cdi', 'st2110-jpegxs'].includes(protocol)">
                        <label for="latency" [ngClass]="{ 'is-invalid': form.submitted && sync_buffer.errors }"
                            >{{ "MAX_SYNC_BUFFER" | translate }} [ms]<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <input
                            type="number"
                            id="sync_buffer"
                            name="sync_buffer"
                            placeholder="{{ 'MAX_SYNC_BUFFER' | translate }}"
                            class="form-control"
                            min="0"
                            max="250"
                            pattern="^\d+$"
                            [(ngModel)]="source.latency"
                            [ngClass]="{ 'is-invalid': form.submitted && sync_buffer.errors }"
                            required
                            #sync_buffer="ngModel"
                        />
                        <div *ngIf="sync_buffer.invalid" class="invalid-feedback">
                            <div *ngIf="sync_buffer.errors.required">{{ "MAX_SYNC_BUFFER" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="sync_buffer.errors.pattern">
                                {{ "MAX_SYNC_BUFFER" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                            </div>
                        </div>
                    </div>
                    <!-- Source configuration -->
                    <!-- Currently extended CDI and JPEGXS sources are configured on flow -->
                    <div class="form-section-title" *ngIf="!['cdi', 'st2110-jpegxs'].includes(protocol)">
                        <h3 class="section-title" title="{{ 'SOURCE' | translate }} {{ 'CONFIGURATION' | translate }}">
                            {{ "SOURCE" | translate }} {{ "CONFIGURATION" | translate }}
                        </h3>
                    </div>

                    <!-- AWS Account -->
                    <div class="form-group" *ngIf="protocol === 'elemental_link'">
                        <label for="aws_account_id" [ngClass]="{ 'is-invalid': form.submitted && awsAccount.errors }">
                            {{ "AWS_ACCOUNT" | translate }}<fa-icon icon="asterisk" size="xs" *ngIf="!isEdit"></fa-icon>
                        </label>
                        <ng-select
                            id="aws_account_id"
                            name="aws_account_id"
                            required
                            [items]="awsAccounts"
                            [clearable]="false"
                            bindValue="id"
                            bindLabel="name"
                            placeholder="{{ 'SELECT' | translate }} {{ 'AWS_ACCOUNT' | translate }}"
                            [(ngModel)]="source.elemental_link_account_id"
                            [disabled]="isEdit"
                            (change)="updateAWSAccountRegions()"
                            #awsAccount="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && awsAccount.errors }"
                        >
                        </ng-select>
                        <div *ngIf="awsAccount.invalid" class="invalid-feedback">
                            <div *ngIf="awsAccount.errors.required">{{ "AWS_ACCOUNT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Region -->
                    <div class="form-group" *ngIf="protocol === 'elemental_link'">
                        <label for="region" [ngClass]="{ 'is-invalid': form.submitted && region.errors }">
                            {{ "REGION" | translate }}<fa-icon icon="asterisk" size="xs" *ngIf="!isEdit"></fa-icon>
                        </label>
                        <ng-select
                            id="region"
                            name="region"
                            required
                            [items]="awsRegions"
                            [clearable]="false"
                            placeholder="{{ 'SELECT' | translate }} {{ 'REGION' | translate }}"
                            [(ngModel)]="source.elemental_link_region"
                            (change)="updateAWSRegion()"
                            bindLabel="name"
                            bindValue="id"
                            #region="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && region.errors }"
                            [disabled]="isEdit || !source.elemental_link_account_id || awsRegions.length === 0"
                            [loading]="awsAccountRegionsLoading"
                        >
                        </ng-select>
                        <div *ngIf="region.invalid" class="invalid-feedback">
                            <div *ngIf="region.errors.required">{{ "REGION" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Elemental Link -->
                    <div class="form-group" *ngIf="protocol === 'elemental_link'">
                        <label for="elemental_link_id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.elemental_link_id?.errors }"
                            >{{ "ELEMENTAL_LINK" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <ng-select
                            id="elemental_link_id"
                            name="elemental_link_id"
                            [items]="awsElementalLinks"
                            [clearable]="false"
                            bindValue="Id"
                            bindLabel="Id"
                            placeholder="{{ 'SELECT' | translate }} {{ 'ELEMENTAL_LINK' | translate }}"
                            [(ngModel)]="source.elemental_link_id"
                            #elementalLink="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && elementalLink.errors }"
                            required
                            [disabled]="
                                isEdit ||
                                !source.elemental_link_account_id ||
                                !source.elemental_link_region ||
                                awsRegions.length === 0 ||
                                awsElementalLinks.length === 0 ||
                                awsElementalLinksLoading
                            "
                            [loading]="awsElementalLinksLoading"
                        ></ng-select>
                        <div *ngIf="elementalLink.invalid" class="invalid-feedback">
                            <div *ngIf="elementalLink.errors.required">{{ "ELEMENTAL_LINK" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>

                    <!-- Whitelist CIDR -->
                    <div class="form-group" *ngIf="!['cdi', 'st2110-jpegxs', 'elemental_link'].includes(protocol)">
                        <label for="cidr" [ngClass]="{ 'is-invalid': form.submitted && cidr.errors }"
                            >{{ "WHITELIST_CIDR" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><fa-icon icon="info-circle" [ngbTooltip]="CIDRTContent" triggers="hover click" [closeDelay]="500"></fa-icon
                            ><ng-template #CIDRTContent
                                ><p class="mb-0">
                                    {{ "TOOLTIP.MEDIACONNECT_WHITELIST" | translate }}
                                </p></ng-template
                            ><fa-icon *ngIf="isEdit" icon="redo" size="sm" [ngbTooltip]="RestartContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                            <ng-template #RestartContent>{{ "RESTART_REQUIRED" | translate }}</ng-template></label
                        >
                        <input
                            type="text"
                            id="cidr"
                            name="cidr"
                            placeholder="e.g. 0.0.0.0/0"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && cidr.errors }"
                            [(ngModel)]="source.whitelist_cidr"
                            pattern="^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/(3[0-2]|[12]?[0-9]))$"
                            required
                            #cidr="ngModel"
                        />
                        <div *ngIf="cidr.invalid" class="invalid-feedback">
                            <div *ngIf="cidr.errors.required">{{ "WHITELIST_CIDR" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="cidr.errors.pattern">{{ "INVALID_CIDR" | translate }}.</div>
                        </div>
                    </div>

                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="source.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>

                    <!-- Advanced -->
                    <app-advanced-section-form
                        *ngIf="protocol === 'zixi-push' && ['zec', 'broadcaster'].includes(sourceType)"
                        [showBilling]="false"
                        [showAutopullBilling]="false"
                        [showMTUField]="false"
                        [showAutoMTU]="false"
                        [showInputDeviceBind]="false"
                        [model]="source"
                        [form]="form"
                        [isEdit]="isEdit"
                        [showFrozenVideoMinimalDuration]="false"
                        [showBlancVideoMinimalDuration]="false"
                        [showFECControls]="true"
                    ></app-advanced-section-form>

                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />

                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" (click)="back()" *ngIf="!isEdit && !isClone" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && (form.invalid || nameControl.invalid || tagsControl.invalid) }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
